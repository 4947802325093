<template lang="pug">
.reset-password
  h1 Recuperar senha

  .form(v-if="!success")
    p Caso tenha perdido acesso a sua conta Magenta, você pode pedir para redefinir a sua senha nos informando seu e-mail abaixo.

    form(@submit.prevent="resetPassword")
      TextInput(
        label="E-mail",
        type="email",
        v-model="state.email",
        @blur="v$.email.$touch",
        :errors="v$.email.$errors",
        autocomplete="email")

      button(v-show="false", type="submit")

      .actions
        Button.large(label="Recuperar senha", rounded, to="", @click.prevent="resetPassword", :loading="loading")

  .success(v-else)
    p Em instantes você deve receber um e-mail com instruções sobre como você pode redefinir a senha para a sua conta Magenta.

    .actions
      Button.large(label="Voltar", :to="{ name: 'sign-in' }", rounded)

</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'

import useVuelidate from '@vuelidate/core'

import AuthService from '~/services/auth'
import { useAuth } from '~/store/auth'
import { required, email } from '~/scripts/validators/validators'
import { useGtm } from '@gtm-support/vue-gtm'

const notifications = useNotifications()
const gtm = useGtm()

definePageMeta({
  middleware: () => {
    const auth = useAuth()
    const notifications = useNotifications()

    if (auth.isAuthenticated) {
      notifications.warning("Você já está logado.")

      return navigateTo({ name: "dashboard" })
    }
  }
})

const loading = ref(false)
const success = ref(false)

const state = reactive({
  email: ""
});

const rules = {
  email: {
    email,
    required
  }
}

const v$ = useVuelidate(rules, state)

const resetPassword = async () => {
  loading.value = true

  if (await v$.value.$validate()) {
    try {
      await AuthService.initiateResetCredential({ email: state.email })

      gtm?.trackEvent({
        category: 'reset_password',
        action: 'reset_password_initiated',
        value: null
      })

      success.value = true
    } catch(error: FetchError | any) {
      if ("status" in error && error.status == 404) {
        notifications.warning("Não encontramos nenhuma conta Magenta com esse e-mail.")
      } else {
        captureException(new Error('Failed to request password reset', { cause: error}))

        notifications.error("Ocorreu um erro ao tentar recuperar sua senha. Por favor, tente novamente mais tarde.")
      }
    }
  } else {
    notifications.warning("Verifique se o e-mail foi preenchido corretamente")
  }

  loading.value = false
}
</script>
<style lang="sass" scoped>
.reset-password
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: center
  animation: fade-in .2s forwards

  h1
    text-align: left

  .actions
    display: flex
    flex-direction: column
    margin: 32px 0
    align-items: center

    .large
      text-align: center
      font-size: 24px
      padding: 4px 32px
</style>